import { Box, Typography } from "@mui/material";
import { ReactComponent as PartnerArrow } from "../assets/icons/partner-arrow.svg";
import Translate from "./Translate";

import Partner0 from "../assets/icons/partners/0.svg";
import Partner1 from "../assets/icons/partners/1.svg";
import Partner2 from "../assets/icons/partners/2.svg";
import Partner3 from "../assets/icons/partners/3.svg";
import Partner4 from "../assets/icons/partners/4.svg";
import Partner5 from "../assets/icons/partners/5.svg";
import Partner6 from "../assets/icons/partners/6.svg";
import Partner7 from "../assets/icons/partners/7.svg";
import Partner8 from "../assets/icons/partners/8.svg";
import Partner9 from "../assets/icons/partners/9.svg";
import Partner10 from "../assets/icons/partners/10.svg";
import Partner11 from "../assets/icons/partners/11.svg";
import Partner12 from "../assets/icons/partners/12.svg";
import Partner13 from "../assets/icons/partners/13.svg";
import Partner14 from "../assets/icons/partners/14.svg";
import Partner15 from "../assets/icons/partners/15.svg";
import Partner16 from "../assets/icons/partners/16.svg";
import Partner17 from "../assets/icons/partners/17.svg";
import Partner18 from "../assets/icons/partners/18.svg";
import Partner19 from "../assets/icons/partners/19.svg";

const partners = [
  Partner0,
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
  Partner9,
  Partner10,
  Partner11,
  Partner12,
  Partner13,
  Partner14,
  Partner15,
  Partner16,
  Partner17,
  Partner18,
  Partner19,
];

export function Partners() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        background: "#18181A",
        padding: "140px 0",
        "@media(max-width: 640px)": {
          padding: "40px 20px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1440px",
          display: "grid",
          gap: "0 50px",
          gridTemplateColumns: "537px 1fr",
          "@media(max-width: 640px)": {
            gridTemplateColumns: "1fr",
            gridTemplateRows: "auto auto",
            gap: "30px 0",
          },
        }}
      >
        <Box
          sx={{
            padding: "70px 50px 70px 70px",
            background: "#242426",
            height: "630px",
            "@media(max-width: 640px)": {
              padding: "30px",
              height: "auto",
            },
          }}
        >
          <Translate>
            <Typography
              sx={{
                color: "#DBFD6D",
                fontSize: "24px",
                fontWeight: 700,
                textTransform: "uppercase",
                "@media(max-width: 640px)": {
                  fontSize: "16px",
                },
              }}
            >
              Partners & Portfolios
            </Typography>
          </Translate>

          <Translate>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "52px",
                fontWeight: 700,
                margin: "24px 0 0 0",
                lineHeight: "120%",
                "@media(max-width: 640px)": {
                  fontSize: "34px",
                },
              }}
            >
              WE ARE EVEN BETTER AS WE ARE WITH THE INCREDIBLE AND INVALUABLE
              COMPANY
            </Typography>
          </Translate>

          <Box sx={{ margin: "30px 0 0 0" }}>
            <Translate>
              <PartnerArrow />
            </Translate>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "30px",
            "@media(max-width: 640px)": {
              justifyContent: "space-around",
              gap: "30px 0",
            },
          }}
        >
          {partners.map((partner, index) => {
            return (
              <Translate key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#ffffff",
                    width: "180px",
                    height: "102px",
                    "@media(max-width: 640px)": {
                      width: "160px",
                      height: "96px",
                    },
                  }}
                >
                  <img style={{ maxWidth: "100%" }} src={partner} alt="" />
                </Box>
              </Translate>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
