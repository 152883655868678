import { Box, ThemeProvider } from "@mui/material";

import { Banner } from "./components/Banner";
import { Strategy } from "./components/Strategy";
import { Professionals } from "./components/Professionals";
import { Partners } from "./components/Partners";
import { Footer } from "./components/Footer";

export default function App() {
  return (
    <ThemeProvider
      theme={{
        typography: {
          fontFamily: "'Visia Pro', 'Roboto', sans-serif",
        },
      }}
    >
      <Box>
        <Banner />
        <Box sx={{ margin: "-90px 0 0 0" }}>
          <Strategy />
        </Box>
        <Box
          sx={{
            margin: "130px 0 0 0",
            "@media(max-width: 640px)": {
              margin: "40px 0 0 0",
            },
          }}
        >
          <Professionals />
        </Box>

        <Partners />

        <Footer />
      </Box>
    </ThemeProvider>
  );
}
