import { Box, Typography } from "@mui/material";
import Translate from "./Translate";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { ReactComponent as LogoText } from "../assets/icons/logo-text.svg";

export function Footer() {
  return (
    <Box
      sx={{
        background: "#ECEDE7",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "30px 0 0 0",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: "-250px",
          bottom: "-300px",
          width: "555px",
          height: "555px",
          borderRadius: "555px",
          background: "#DBFD6D",
          filter: "blur(67px)",
        }}
      />

      <Box
        sx={{
          width: "100%",
          maxWidth: "1440px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
            "@media(max-width: 640px)": {
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0 40px",
              "@media(max-width: 640px)": {
                gap: "0 10px",
              },
            }}
          >
            <Translate>
              <Logo />
            </Translate>

            <Translate>
              <LogoText />
            </Translate>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0 30px",
              "@media(max-width: 640px)": {
                gap: "0 40px",
                margin: "40px 0",
              },
            }}
          >
            <a
              target="_blank"
              href="https://t.me/RayVamos"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#000",
              }}
            >
              <Translate>
                <Typography sx={{ fontSize: "16px" }}>Telegram</Typography>
              </Translate>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/ray63861503"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#000",
              }}
            >
              <Translate>
                <Typography sx={{ fontSize: "16px" }}>X/Twitter</Typography>
              </Translate>
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            margin: "60px 0 0 0",
            padding: "0 20px",
            "@media(max-width: 640px)": {
              margin: "0",
            },
          }}
        >
          <Translate>
            <Typography fontSize="12px">EMAIL</Typography>
            <Typography
              fontWeight={700}
              mt="20px"
              sx={{
                fontSize: "40px",
                "@media(max-width: 640px)": {
                  fontSize: "24px",
                },
              }}
            >
              Ray@walabs.org
            </Typography>
          </Translate>
        </Box>

        <Box
          sx={{
            margin: "90px 0 0 0",
            borderTop: "1px solid #18181A",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "15px 80px",
              borderRight: "1px solid #18181A",
              borderLeft: "1px solid #18181A",
              "@media(max-width: 640px)": {
                padding: " 15px 0",
                borderRight: "none",
                borderLeft: "none",
              },
            }}
          >
            <Typography fontSize="16px">
              © 2024 WA LABS. All rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
