import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Translate from "./Translate";
import { ReactComponent as ButtonArrow } from "../assets/icons/button-arrow.svg";
import { ReactComponent as LogoWhite } from "../assets/icons/logo-white.svg";
import { ReactComponent as LogoTextWhite } from "../assets/icons/logo-text-white.svg";
import { ReactComponent as Arrow } from "../assets/icons/arrow.svg";

const useStyles = makeStyles(() => {
  return {
    arrow: {
      "@media(max-width: 640px)": {
        width: "32px",
        height: "32px",
      },
    },
  };
});

export function Banner() {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          background: "#18181A",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "0 0 230px 0",
          "@media(max-width: 640px)": {
            padding: "0 0 180px 0",
          },
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1440px", padding: "0 20px" }}>
          <Box
            sx={{
              margin: "60px 0 0 0",
              display: "flex",
              justifyContent: "space-between",
              "@media(max-width: 640px)": {
                margin: "30px 0 0 0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0 10px",
              }}
            >
              <LogoWhite />
              <LogoTextWhite />
            </Box>

            <Arrow className={classes.arrow} />
          </Box>
          <Box
            sx={{
              margin: "150px 0 0 0",
              maxWidth: "920px",
              "@media(max-width: 640px)": {
                margin: "70px 0 0 0",
              },
            }}
          >
            <Translate>
              <Typography
                fontSize="74px"
                fontWeight={700}
                color="#ffffff"
                sx={{
                  lineHeight: "120%",
                  textTransform: "uppercase",
                  "@media(max-width: 640px)": {
                    fontSize: "42px",
                  },
                }}
              >
                In Pursuit of Greatness Web 3.0 & AI +
              </Typography>
            </Translate>

            <Translate>
              <Typography
                mt="30px"
                fontSize="22px"
                fontWeight={700}
                color="#ffffff"
                maxWidth={575}
                lineHeight="140%"
              >
                To Be the Best Financial and Strategic Service Provider in Web
                3.0 and AI
              </Typography>
            </Translate>

            <Translate>
              <a href="mailto:Ray@walabs.io" style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    width: "250px",
                    height: "68px",
                    background: "#DBFD6D",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: "30px 0 0 0",
                    padding: "0 0 0 32px",
                    "&:hover": {
                      background: "#ffffff",
                    },
                  }}
                >
                  <ButtonArrow />
                  <Typography
                    fontSize="14px"
                    color="#1D1D1D"
                    fontWeight={700}
                    sx={{ margin: "0 0 0 39px" }}
                  >
                    Contact Us
                  </Typography>
                </Box>
              </a>
            </Translate>
          </Box>
        </Box>
      </Box>
    </>
  );
}
