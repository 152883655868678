import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Translate from "./Translate";
import { ReactComponent as StrategyArrow } from "../assets/icons/strategy-arrow.svg";
import { ReactComponent as Strategy0 } from "../assets/icons/strategy0.svg";
import { ReactComponent as Strategy1 } from "../assets/icons/strategy1.svg";

const useStyles = makeStyles(() => {
  return {
    icon: {
      width: "48px",
      height: "48px",
    },
  };
});

export function Strategy() {
  const classes = useStyles();

  return (
    <Box
      sx={{
        padding: "0 20px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          maxWidth: "1440px",
          gap: "0 10px",
          height: "770px",
          "@media(max-width: 640px)": {
            flexDirection: "column",
            height: "auto",
            gap: "20px 0",
          },
        }}
      >
        <Box
          sx={{
            width: "537px",
            background: "#242426",
            padding: "70px",
            height: "770px",
            "@media(max-width: 640px)": {
              width: "100%",
              padding: "30px",
              height: "auto",
            },
          }}
        >
          <Translate>
            <Typography
              fontSize="24px"
              fontWeight={700}
              color="#DBFD6D"
              textTransform="uppercase"
              sx={{
                "@media(max-width: 640px)": {
                  fontSize: "16px",
                },
              }}
            >
              Strategy & Dedication
            </Typography>
          </Translate>

          <Translate>
            <Typography
              fontWeight={700}
              color="#ffffff"
              mt="24px"
              textTransform="uppercase"
              sx={{
                fontSize: "52px",
                "@media(max-width: 640px)": {
                  fontSize: "35px",
                },
              }}
            >
              Our Commitment & Goals
            </Typography>
          </Translate>

          <Box
            sx={{
              position: "absolute",
              left: "70px",
              bottom: "70px",
              "@media(max-width: 640px)": {
                position: "relative",
                margin: "50px 0 0 0",
                left: 0,
                bottom: 0,
              },
            }}
          >
            <StrategyArrow />
          </Box>
        </Box>

        <>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "10px 0",
              height: "100%",
              "@media(max-width: 640px)": {
                flex: "auto",
                height: "auto",
              },
            }}
          >
            <>
              <Box
                sx={{
                  background: "#ECEDE7",
                  flex: "50%",
                  padding: "70px",
                  "@media(max-width: 640px)": {
                    padding: "30px",
                  },
                }}
              >
                <Translate>
                  <Strategy0 className={classes.icon} />
                </Translate>

                <Translate>
                  <Typography
                    mt="60px"
                    sx={{
                      lineHeight: "150%",
                      fontSize: "24px",
                      "@media(max-width: 640px)": {
                        fontSize: "20px",
                        lineHeight: "130%",
                      },
                    }}
                  >
                    Discovering, nurturing, and supporting the top 1‰ promising
                    teams in Web3 and AI+, together building and welcoming the
                    splendid future.
                  </Typography>
                </Translate>
              </Box>
            </>

            <>
              <Box
                sx={{
                  background: "#ECEDE7",
                  flex: "50%",
                  padding: "70px",
                  "@media(max-width: 640px)": {
                    padding: "30px",
                  },
                }}
              >
                <Translate>
                  <Strategy1 className={classes.icon} />
                </Translate>
                <Box mt="60px">
                  <Translate>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        "@media(max-width: 640px)": {
                          fontSize: "20px",
                          lineHeight: "130%",
                        },
                      }}
                    >
                      Who As are with;
                    </Typography>
                    <Typography
                      mt="10px"
                      sx={{
                        fontSize: "24px",
                        "@media(max-width: 640px)": {
                          fontSize: "20px",
                          lineHeight: "130%",
                        },
                      }}
                    >
                      When As are empowered;
                    </Typography>
                    <Typography
                      mt="10px"
                      sx={{
                        fontSize: "24px",
                        "@media(max-width: 640px)": {
                          fontSize: "20px",
                          lineHeight: "130%",
                        },
                      }}
                    >
                      Where As are even better.
                    </Typography>
                  </Translate>
                </Box>
              </Box>
            </>
          </Box>
        </>
      </Box>
    </Box>
  );
}
