import { Box, Typography } from "@mui/material";
import Map from "../assets/icons/map.svg";
import Translate from "./Translate";

export function Professionals() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#ECEDE7",
          padding: "130px 0",
          "@media(max-width: 640px)": {
            padding: "60px 20px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Translate>
            <Typography
              sx={{
                maxWidth: "1160px",
                fontSize: "40px",
                fontWeight: 700,
                textAlign: "center",
                lineHeight: "120%",
                "@media(max-width: 640px)": {
                  fontSize: "24px",
                },
              }}
            >
              We are a team of seasoned professionals from computer science, AI,
              Blockchain, investment banking and business administration from
              around the world.
            </Typography>
          </Translate>

          <Box
            sx={{
              margin: "100px 0 0 0",
              "@media(max-width: 640px)": {
                margin: "40px 0 0 0",
              },
            }}
          >
            <Translate
              beforeClass="before-translate-image"
              afterClass="after-translate-image"
            >
              <img width="100%" src={Map} alt="" />
            </Translate>
          </Box>
        </Box>
      </Box>
    </>
  );
}
